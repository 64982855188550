<i18n locale="th" lang="yaml" >
image.comment.add : "เพิ่มรายการ"
</i18n>

<template>
  <div :class="containerCssClasses">
    <div class="image-comment-canvas">
      <canvas ref="canvas" />
    </div>
    <div v-if="!readonly" class="image-comment-command">
      <a-button icon="plus" type="primary" :disabled="!canAddItem" :size="size" tabindex="-1" @click="addNewItem">
        {{$t('image.comment.add')}}
      </a-button>
    </div>
    <div class="image-comment-items">
      <div v-for="(item,index) of items" :key="item.shapeId"
        :class="getItemClass(item)" >
        <template v-if="readonly">
          <div class="item-no" @click="selectItem(item,true)"><p>{{index + 1}}.</p></div>
          <div class="item-input-read-only" @click="selectItem(item,true)"><p>{{item.remark | emptyCheck('-')}}</p></div>
        </template>
        <template v-else>
          <span class="item-no" @click="selectItem(item)">{{index + 1}}.</span>
          <a-input v-model="item.remark" class="item-input" :size="size" @focus="selectItem(item)"  @blur="unselectItem(item)"/>
          <a-button icon="close"
            class="item-btn"
            type="danger" shape="circle" size="small"
            tabindex="-1" @click="deleteItem(item)" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {fabric} from "fabric"
import _debounce from "lodash/debounce"
import DeviceMixin from "@mixins/DeviceMixin.vue"

const DEFAULT_CIRCLE = {
  borderColor: 'green',
  borderDashArray: [3, 3] ,
  cornerColor: 'green',
  cornerStyle : 'circle',
  cornerSize: 10,
  transparentCorners: false,
  radius : 25 ,
  fill: 'transparent',
  top: 15,
  left: 15,
  stroke : "red" ,
  strokeWidth : 2 ,
  strokeUniform: false,
}
export default {
  mixins : [DeviceMixin],
  fCanvas : null,
  props : {
    imageUrl : {
      type : String,
      default : ''
    } ,
    readonly : {
      type : Boolean,
      default: false
    } ,
    title : {
      type : String,
      default :undefined
    } ,
    maxItems : {
      type : Number,
      default : 15,
    } ,
    widthOffset : {
      type : Number ,
      default : 0
    } ,
    width : {
      type : Number ,
      default : 600
    } ,
    height : {
      type : Number ,
      default : 250
    } ,
    canvasWidth : {
      type : Number,
      default : -1
		} ,
		size : {
			type : String,
			default : undefined
		}
  } ,
  data() {
    return {
      items : [],
      currentShapeId : 0 ,
      selectedShapeId : -1,

      currentMobile : true,
    }
  } ,
  computed : {
    canAddItem() {
      return !this.readonly && (this.maxItems <= 0 || this.items.length < this.maxItems)
		} ,
		containerCssClasses() {
			return [
				'image-comment-container' ,
				{
					'image-comment-container-small' : this.size === 'small'
				}
			]
		}
  } ,
  created() {
    this.onWindowResize = _debounce(this._onWindowResize,200)
    window.addEventListener('resize',this.onWindowResize)
  } ,
  mounted() {
    const canvas = new fabric.Canvas(this.$refs.canvas,{
      selection : false,

    })
    if (this.$notEmpty(this.imageUrl)) {
      canvas.setBackgroundImage(this.imageUrl,canvas.renderAll.bind(canvas),{
        opacity : 0.8
      });
    }
    this.$options.fCanvas = canvas
    this._onWindowResize()
  } ,
  beforeDestroy() {
    window.removeEventListener('resize',this.onWindowResize)
    if (this.$options.fCanvas) {
      this.$options.fCanvas.dispose()
      this.$options.fCanvas = null
    }
  } ,
  methods : {
    _onWindowResize() {
      const fCanvas = this.$options.fCanvas
      if (!fCanvas)
        return
      let width = -1
      if(this.isMobile) {
        width = window.innerWidth - this.widthOffset - 2
      } else if (this.currentMobile) {
        width = (this.canvasWidth > 0) ? this.canvasWidth : this.width
      }
      this.currentMobile = this.isMobile
      if (width > 0) {
        const ratio = (this.width > 0) ? width / this.width : 1
        fCanvas.setWidth(width)
        fCanvas.setHeight(ratio * this.height)
        fCanvas.setZoom(ratio)
      }
    } ,
    _renderNo(ctx,left,top,styleOverride,fabricObj) {
      if (!('item' in fabricObj)) {
        return
      }
      let index = this.items.findIndex((i) => i.shapeId == fabricObj.item.shapeId)
      if (index < 0) {
        return
      }
      index = "" + (index+1)
      const bound = fabricObj.getBoundingRect()
      ctx.save();
      ctx.font="18px Arial";
      ctx.fillStyle = "red";
      ctx.fillText(index, bound.left+bound.width+4, bound.top+4);
      ctx.restore();
    } ,
    findShape(item) {
      if (!this.$options.fCanvas)
        return null
      else
        return this.$options.fCanvas.getObjects().find((i)=> 'item' in i && i.item.shapeId == item.shapeId)
    } ,
    addShape(item,options={}) {
      const circleOptions = {
        ...DEFAULT_CIRCLE,
        ...options,
        item ,
      }
      if (this.readonly) {
        circleOptions.hasControls = false
        circleOptions.lockMovementX = true
        circleOptions.lockMovementY = true
      }
      const mycomponent = this
      const fCanvas = this.$options.fCanvas

      const circle = new fabric.Circle(circleOptions)
      if (!this.readonly) {
        circle.controls.viewControl = new fabric.Control({
          x : 1000 ,
          y : 1000,
          cornerSize : 1,
          cursorStyle: 'pointer',
          selectable : false,
          render: this._renderNo,
        })
        if ('mtr' in circle.controls) {
          circle.controls.mtr.offsetY = -25
        }
      } else {
        let index = this.items.findIndex((i) => i.shapeId == item.shapeId)
        if (index >= 0) {
          const bound = circle.getBoundingRect()
          index = "" + (index+1)
          fCanvas.add(new fabric.Textbox(index,{
            left: bound.left+bound.width+4,
            top:  bound.top - 8,
            fontSize: 16 ,
            fontFamily: 'Arial',
            fill : "green",
             selectable : false,
          }))
        }
      }
      circle.on('selected',function(options) {
        if ('item' in this) {
          mycomponent.selectedShapeId = this.item.shapeId
          fCanvas.backgroundImage.opacity = 0.3
        }
      })
      circle.on('deselected',function(options) {
        if (fCanvas.getActiveObject())
          return
        mycomponent.selectedShapeId = -1
        fCanvas.backgroundImage.opacity = 0.8
      })
      fCanvas.add(circle)
    } ,
    addNewItem() {
      if (!this.canAddItem)
        return
      const newItem = {
        shapeId : this.currentShapeId++ ,
        remark : '',
      }
      this.items.push(newItem)
      const top = 25 + ((newItem.shapeId % 12)* 5)
      const left = 50 + ((newItem.shapeId % 12) * 30)
      this.addShape(newItem,{
        top,
        left,
      })
      this.selectItem(newItem)
    } ,
    clearCanvas() {
      const fCanvas = this.$options.fCanvas
      if (fCanvas) {
        for(const obj of fCanvas.getObjects()) {
          fCanvas.remove(obj)
        }
      }
      this.items = []
      this.currentShapeId = 0
      this.selectedShapeId = -1
    } ,
    selectItem(item,toggle=false) {
      if (toggle && this.selectedShapeId == item.shapeId) {
        this.unselectItem(item)
        return
      }
      const obj = this.findShape(item)
      if (obj) {
        this.$options.fCanvas.setActiveObject(obj)
        this.$options.fCanvas.requestRenderAll()
      }
    } ,
    unselectItem(item) {
      this.$options.fCanvas.discardActiveObject()
      this.$options.fCanvas.requestRenderAll()
    } ,
    deleteItem(item) {
      const obj = this.findShape(item)
      if (obj) {
        this.$options.fCanvas.remove(obj)
      }
      const index = this.items.findIndex((i)=>i.shapeId == item.shapeId)
      if (index >= 0)
        this.items.splice(index,1)
    } ,
    getItemClass(item) {
      return [
        'image-comment-item' , {
          'readonly' : this.readonly ,
          'selected' : this.selectedShapeId == item.shapeId
        }
      ]
    } ,
    setData(items) {
      this.clearCanvas()
      let lastId = 0
      items.forEach((item) => {
        this.items.push(item.item)
        this.addShape(item.item,item.shape)
        if (item.item.shapeId > lastId)
          lastId = item.item.shapeId
      })
      this.currentShapeId = lastId + 1
    } ,
    getData() {
      const rtn = []
      this.$options.fCanvas.getObjects().forEach((obj)=>{
        if (!('item' in obj))
          return
        rtn.push({
          item : {...obj.item} ,
          shape : {
            top : obj.top,
            left : obj.left,
            radius : obj.radius,
            width : obj.width,
            height : obj.height,
            angle : obj.angle,
            scaleX : obj.scaleX,
            scaleY : obj.scaleY,
          }
        })
      })
      return rtn
    }

  }
}
</script>
<style lang="less">
.canvas-container {
	margin-left : auto;
	margin-right : auto;
}
</style>
<style lang="less" scoped>
.image-comment-container {
  width : 600px;
  width : fit-content;
  min-width : 275px;
  margin : 0 auto;
}
.image-comment-canvas {
  background : @white;
  display : inline-block;
  margin : 0 auto;
  border : 1px solid @border-color-base;
  border-radius: @border-radius-base;
}
.image-comment-command {
  text-align : right;
  padding-top : 8px;
  padding-bottom : 8px;
}
.image-comment-item {
  padding : 4px 8px;
  display : flex;
  flex-wrap: nowrap;
  text-align : left;
  .item-no {
    margin-right : 4px;
    line-height : 32px;
    user-select: none;
  }
  .item-input {
    flex-grow: 1;
    margin-right : 8px;
  }
  .item-input-read-only {
    flex-grow: 1;
    margin-top: 3px;
    border-width: 1px;
    text-indent: 5px;
    border-radius: 5px;
    border-color: gray;
    border-style: ridge;
    margin-right : 8px;
  }
  .item-btn {
    margin-top : 4px;
  }
  &.selected {
    background-color : @green-2;
  }

  &.readonly {
    cursor: pointer;
    padding : 8px;
    user-select: none;
    .item-no , .item-input {
      line-height : 1.4;
    }
	}

	.image-comment-container-small & {
		.item-no {
			line-height : 22px;
			font-size : 0.95em;
		}
		.item-btn {
			margin-top : 2px;
			&.ant-btn-icon-only.ant-btn-sm {
				width : 20px;
				height : 20px;
				min-width: 20px;
				font-size : 12px;
			}
		}
		input {
			font-size : 0.95em;
		}
	}
}
</style>
