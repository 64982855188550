<template>
	<div>
		<a-select v-if="canChangeUnit" :value="timeUnit"
			class="flight-time-select"
			:get-popup-container="getPopupContainer"
			@change="handleUnitChange">
			<a-select-option value="second">
				{{$t('common.second')}}
			</a-select-option>
			<a-select-option value="minute">
				{{$t('common.minute')}}
			</a-select-option>
			<a-select-option value="hour">
				{{$t('common.hour')}}
			</a-select-option>
		</a-select>
		<div v-if="timeUnit === 'second'" key="secondMode" class="flight-time-input">
			<a-input-number
				:value="timeSeconds" :min="0"
				:step="1" :precision="0" class="myinput-number"
				length="15" @change="handleSecondChange"/>
			<span class="ant-form-text">{{$t('common.second')}}</span>
		</div>
		<div v-else-if="timeUnit === 'minute'" key="minuteMode" class="flight-time-input">
			<a-input-number
				:value="valueMinute" :min="0"
				:step="1" :precision="0" class="myinput-number"
				length="15" @change="handleMinuteChange"/>
			<span class="ant-form-text">{{$t('common.minute')}}</span>
			<a-input-number
				:value="valueMinuteSeconds" :min="0"
				:max="59" :step="1" :precision="0" class="myinput-number"
				length="15" @change="handleMinuteSecondChange"/>
			<span class="ant-form-text">{{$t('common.second')}}</span>
		</div>
		<div v-else class="flight-time-input">
			<a-input-number
				:value="valueHour" :min="0"
				:step="1" :precision="0" class="myinput-number"
				length="10" @change="handleHourHourChange"/>
			<span class="ant-form-text">{{$t('common.hour')}}</span>
			<a-input-number
				:value="valueHourMinutes" :min="0"
				:max="59" :step="1" :precision="0" class="myinput-number"
				length="3" @change="handleHourMinuteChange"/>
			<span class="ant-form-text">{{$t('common.minute')}}</span>
			<template v-if="enableHourSeconds">
				<a-input-number
					:value="valueHourSeconds" :min="0"
					:max="59" :step="1" :precision="0" class="myinput-number"
					length="5" @change="handleHourSecondChange"/>
				<span class="ant-form-text">{{$t('common.second')}}</span>
			</template>
		</div>
	</div>

</template>

<script>
import {InputNumber,Select} from "ant-design-vue"
import PopupMixin from "@mixins/PopupMixin.vue"
export default {
	components : {
		"a-input-number" : InputNumber ,
		"a-select" : Select, "a-select-option" : Select.Option
	} ,
	mixins : [PopupMixin] ,
	model: {
    prop: 'value',
    event: 'change'
  },
	props : {
		value : {
			type : Number ,
			default : undefined
		} ,
		canChangeUnit : {
			type : Boolean,
			default : false
		} ,
		defaultUnit : {
			type : String,
			default : "hour"
		} ,
		enableHourSeconds : {
			type : Boolean,
			default : false,
		}
	} ,
	data(){
		return {
			timeUnit : this.defaultUnit ,

			timeSeconds : 0,

			valueMinute : null,
			valueMinuteSeconds : 0,

			valueHour : null,
			valueHourMinutes : 0,
			valueHourSeconds : 0 ,
		}
	} ,
	watch : {
		value(newVal) {
			if (newVal !== this.timeSeconds)
				this._updateFields(newVal,{hour: true,minute:true,second:true})
		}
	} ,
	methods : {
		_updateFields(value,touch) {
			if (value === null) {
				if (touch.second)
					this.timeSeconds = null
				if (touch.minute) {
					this.valueMinute = null
					this.valueMinuteSeconds = null
				}
				if (touch.hour) {
					this.valueHour = null
					this.valueHourMinutes = null
					this.valueHourSeconds = null
				}
			} else {
				if (touch.second)
					this.timeSeconds = value
				if (touch.minute) {
					this.valueMinute = Math.floor(value/60)
					this.valueMinuteSeconds =  value - (this.valueMinute * 60)
					if (this.valueMinute === 0)
						this.valueMinute = null
				}
				if (touch.hour) {
					this.valueHour = Math.floor(value/(60*60))
					const left = value - (this.valueHour * 60 *60)
					this.valueHourMinutes = Math.floor(left/60)
					this.valueHourSeconds = left - (this.valueHourMinutes * 60)

					if (this.valueHour === 0)
						this.valueHour = null
					if (this.valueHour == 0 && this.valueHourMinutes === 0)
						this.valueHourMinutes = null
				}
			}
		} ,
		handleUnitChange(value) {
			this.timeUnit = value;
		} ,

		handleSecondChange(value) {
			this._updateFields(value,{hour: true,minute:true,second:true})
			this.emitChange();
		} ,
 		handleMinuteChange(value) {
			this.valueMinute = value
			this._timeSecondsFromMinute()
		} ,
		handleMinuteSecondChange(value) {
			this.valueMinuteSeconds = value
			this._timeSecondsFromMinute()
		} ,
		handleHourHourChange(value) {
			this.valueHour = value
			this._timeSecondsFromHour()
		} ,
		handleHourMinuteChange(value) {
			this.valueHourMinutes = value
			this._timeSecondsFromHour()
		} ,
		handleHourSecondChange(value) {
			this.valueHourSeconds = value
			this._timeSecondsFromHour()
		} ,
		_timeSecondsFromMinute() {
			this.timeSeconds = (this.valueMinute * 60) + this.valueMinuteSeconds
			this._updateFields(this.timeSeconds,{hour:true})
			this.emitChange();
		} ,
		_timeSecondsFromHour() {
			this.timeSeconds = (this.valueHour * 60 *60) + (this.valueHourMinutes * 60);
			if (this.enableHourSeconds) {
				this.timeSeconds += this.valueHourSeconds
			}

			this._updateFields(this.timeSeconds,{minute:true})
			this.emitChange();
		} ,
		emitChange() {
			this.$emit('change',this.timeSeconds);
		}
	} ,

}
</script>

<style lang="less" scoped>
.flight-time-select {
	width:100px;
	margin-right : 16px;
}
.flight-time-input {
	display : inline-block;
	> .myinput-number {
		width : 75px;
	}
}
</style>
