<i18n locale="th" lang="yaml" src="@i18n/service/service.th.yaml"></i18n>
<i18n locale="th" lang="yaml" src="@i18n/service/onfield-service.th.yaml"></i18n>
<i18n locale="th" lang="yaml" >
pre_service.title : "ใบตรวจรับโดรนเลขที่ : {name}"
pre_service.header.page_date : "เวลาที่พิมพ์: {datetime}"
pre_service.header.company_info : "ศูนย์บริการ"
pre_service.header.drone : "โดรน"
pre_service.header.drone.serial : "เลขเครื่อง: {serial}"
pre_service.header.drone.pixhawk : "({pixhawk})"
pre_service.header.drone.model : "รุ่น: {name}"
pre_service.header.report_by : "รับแจ้งโดย"
pre_service.header.drone_receive_date : "โดรนเข้าศูนย์วันที่"
pre_service.header.inspection_date : "วันที่ตรวจเชคโดรน ก่อนส่งมอบ"
pre_service.header.flight_time : "จำนวนชั่วโมงบิน"
pre_service.header.firmware : "Firmware"
pre_service.header.devices : "อุปกรณ์ที่มากับโดรน"
pre_service.sign_warning: "กรุณาลงลายมือชื่อผู้เข้ารับบริการ และผู้รับมอบให้ครบถ้วน"
pre_service.header.devices_unspecified: "ไม่ระบุ"

pre_service.footer.consent.acceptance : "ข้าพเจ้าได้ตรวจสอบและยืนยันความถูกต้องของรายการข้างต้นเรียบร้อยแล้ว และส่งมอบโดรนพร้อมอุปกรณ์เป็นที่เรียบร้อย"
pre_service.footer.signature : "<div>.......................................</div>"
pre_service.footer.name : "<pre>(                  )</pre>"
pre_service.footer.signature.customer : "ผู้เข้ารับบริการ"
pre_service.footer.signature.inspect_by : "ผู้ตรวจสอบ"
pre_service.footer.signature.deliver_by : "ผู้รับมอบ"
pre_service.footer.signature.approve_by : "ผู้อนุมัติ"
pre_service.footer.remark : "หมายเหตุ: "

pre_service.image_comment.front : "ด้านหน้า"
pre_service.image_comment.back : "ด้านหลัง"
pre_service.field.is_not_inspect.message : "ไม่ได้ตรวจรับก่อนซ่อม"
pre_service_modal.pageLoading.tip : "กำลังบันทึกข้อมูล, กรุณารอสักครู่ ..."
tgd1x.pre_service_print.drone.inspect.label : "สภาพของโดรน"

</i18n>

<template>
	<a-modal centered width="800px" :visible="visible" :footer="null" :mask-closable="false" @cancel="handleClose">
		<a-spin wrapper-class-name="tunjai-page-loading" :spinning="loading" size="large" :tip="$t('pre_service_modal.pageLoading.tip')">
			<div id="pre-delivery-form" class="pre-service-modal">
				<div class="page-header">
					<my-page-header :title="$t('pre_service.title', { name: serviceNumber })" />
					<div>{{ $t('pre_service.header.page_date', { datetime: printDate }) }}</div>
				</div>

				<div class="service-detail-container">
					<div v-if="displayCompany" class="service-col">
						<label>{{ $t('pre_service.header.company_info') }}</label>
						<div class="service-val">
							<div class="service-val-row">
								{{ displayCompany.name }}
							</div>
							<div class="service-val-row">
								{{
									(displayCompany.address ? displayCompany.address + ' ' : '') + displayCompany.province + ' ' + displayCompany.postal_code
								}}
							</div>
							<div v-if="displayCompany.contact_phone" class="service-val-row">
								<PhoneLink :value="displayCompany.contact_phone" :view-only="true" />
							</div>
						</div>
					</div>
					<div v-if="!isDeviceOnly" class="service-col">
						<label>{{ $t('pre_service.header.drone') }}</label>
						<div class="service-val">
							<div class="service-val-row">
								{{ $t('pre_service.header.drone.serial', { serial: droneSerial }) }}
							</div>
							<div class="service-val-row">
								{{ $t('pre_service.header.drone.pixhawk', { pixhawk: dronePixhawk }) }}
							</div>
							<div class="service-val-row">
								{{ $t('pre_service.header.drone.model', { name: droneModelName }) }}
							</div>
						</div>
					</div>
					<div class="service-col">
						<label>{{ $t('service.field.customer') }}</label>
						<div class="service-val">
							<div class="service-val-row">
								{{ customerName }}
							</div>
							<div class="service-val-row">
								<PhoneLink :value="customerPhone" :view-only="true" />
								<span style="margin-left: 8px">{{ $t('common.province') }}{{ ' ' + customerProvince }}</span>
							</div>
						</div>
					</div>
					<div class="service-col">
						<label>{{ $t('pre_service.header.drone_receive_date') }}</label>
						<div class="service-val">
							<div class="service-val-row">
								<span v-if="droneReceiveDateTime ">
									{{ droneReceiveDateTime  }}
								</span>
								<span v-else>
									{{ $t('common.unspecify') }}
								</span>
							</div>
						</div>
					</div>
					<div class="service-col">
						<label>{{ $t('pre_service.header.report_by') }}</label>
						<div class="service-val">
							<div class="service-val-row">
								<span v-if="openByUsername">
									{{ openByUsername }}
								</span>
								<span v-else>
									{{ $t('common.unspecify') }}
								</span>
							</div>
						</div>
					</div>
					<div v-if="!isDeviceOnly" class="service-col">
						<label>{{ $t('pre_service.header.flight_time') }}</label>
						<div class="service-val">
							<FlightTimeView :time-seconds="flightTime" />
						</div>
					</div>
					<div v-if="!isDeviceOnly" class="service-col">
						<label>{{ $t('pre_service.header.firmware') }}</label>
						<div class="service-val">
							{{ firmwareVersion }}
						</div>
					</div>
					<div :class="isDeviceOnly ? 'service-special-col' : 'service-col'">
						<label>{{ $t('pre_service.header.devices') }}</label>
						<div class="service-val" style="display: flex; flex-direction: column">
							<div v-for="item in devices" :key="item.id">
								{{ $tenum('drone.devices', item) }}
							</div>
							<div v-if="others !== ''" class="service-val">
								{{ others }}
							</div>
							<div v-if="batteries.no1" class="service-val">
								{{ batteries.no1 }}
							</div>
							<div v-if="batteries.no2" class="service-val">
								{{ batteries.no2 }}
							</div>
						</div>
					</div>
				</div>
				<BlockPane v-if="!service.is_device_only" :block="isNotInspect">
					<span slot="message">
						{{ $t('pre_service.field.is_not_inspect.message') }}
					</span>

					<div class="checklist-row checklist-row-title checklist-row-border">
						<label class="title"> <span>1</span> {{ $t('tgd1x.pre_service_print.drone.inspect.label') }} </label>
					</div>
					<div>
						<PreServiceTGD1XDiagramPrint
							:service="service"
							:checklist-type="checklistType"
							:service-id="serviceId"
							:inspection-data="inspectedData"/>
					</div>
					<PreServiceTGD1XChecklistPrint ref="checklistRef" :service="service" />
				</BlockPane>
				<a-checkbox @change="handleAccept">{{$t('pre_service.footer.consent.acceptance')}}</a-checkbox>

				<div class="signature">
					<div>
						<div v-if="!signData.customer.img" id="customer" class="sign-area" v-html="$t('pre_service.footer.signature')" />
						<div v-else class="sign-area">
							<img :src="signData.customer.img" class="signature-img">
						</div>
						<div v-if="!signData.customer.name" id="customerName" class="signature-item" v-html="$t('pre_service.footer.name')" />
						<div v-else class="signature-item" v-html="`(${signData.customer.name})`"/>
						<div class="signature-item" v-html="$t('pre_service.footer.signature.customer')" />
						<div class="sign-button">
							<a-button id="customerBt" @click="openSignModal($refs.customerRef)"> {{ $t('action.sign') }} </a-button>
						</div>
					</div>
					<div>
						<div v-if="!signData.inspector.img" id="inspector" class="sign-area" v-html="$t('pre_service.footer.signature')" />
						<div v-else class="sign-area">
							<img :src="signData.inspector.img" class="signature-img">
						</div>
						<div v-if="!signData.inspector.name" id="inspectorName" class="signature-item" v-html="$t('pre_service.footer.name')" />
						<div v-else class="signature-item" v-html="`(${signData.inspector.name})`"/>
						<div class="signature-item" v-html="$t('pre_service.footer.signature.inspect_by')" />
						<div class="sign-button">
							<a-button id="inspectorBt" @click="openSignModal($refs.inspectorRef)"> {{ $t('action.sign') }} </a-button>
						</div>
					</div>
					<div>
						<div v-if="!signData.receiver.img" id="receiver" class="sign-area" v-html="$t('pre_service.footer.signature')" />
						<div v-else class="sign-area">
							<img :src="signData.receiver.img" class="signature-img">
						</div>
						<div v-if="!signData.receiver.name" id="receiverName" class="signature-item" v-html="$t('pre_service.footer.name')" />
						<div v-else class="signature-item" v-html="(`(${signData.receiver.name})`)"/>
						<div class="signature-item" v-html="$t('pre_service.footer.signature.deliver_by')" />
						<div class="sign-button">
							<a-button id="receiverBt" @click="openSignModal($refs.receiverRef)"> {{ $t('action.sign') }} </a-button>
						</div>
					</div>
					<div>
						<div v-if="!signData.approver.img" id="approver" class="sign-area" v-html="$t('pre_service.footer.signature')" />
						<div v-else class="sign-area">
							<img :src="signData.approver.img" class="signature-img">
						</div>
						<div v-if="!signData.approver.name" id="approverName" class="signature-item" v-html="$t('pre_service.footer.name')" />
						<div v-else class="signature-item" v-html="(`(${signData.approver.name})`)"/>
						<div class="signature-item" v-html="$t('pre_service.footer.signature.approve_by')" />
						<div class="sign-button">
							<a-button id="approverBt" @click="openSignModal($refs.approverRef)"> {{ $t('action.sign') }} </a-button>
						</div>
					</div>
				</div>
				<div class="page-remark">
					{{ remark ? $t('pre_service.footer.remark') + remark : $t('pre_service.footer.remark') + '-' }}
				</div>
			</div>
			<div class="page-action-right next-button">
				<a-button
					type="primary"
					@click="handleSave">
					{{$t('action.save')}}
				</a-button>
			</div>
			<SignModal ref="customerRef"  @save="handleSign($event, 'customerRef')" />
			<SignModal ref="inspectorRef"  @save="handleSign($event, 'inspectorRef')" />
			<SignModal ref="receiverRef"  @save="handleSign($event, 'receiverRef')" />
			<SignModal ref="approverRef" @save="handleSign($event, 'approverRef')" />
		</a-spin>
	</a-modal>
</template>

<script>
import { mapState } from 'vuex'
import PhoneLink from '@components/common/PhoneLink.vue'
import PreServiceTGD1XChecklistPrint from '@components/service/tgd1x/PreServiceTGD1XChecklistPrint.vue'
import PreServiceTGD1XDiagramPrint from '@components/service/tgd1x/PreServiceTGD1XDiagramPrint.vue'
import FlightTimeView from '@components/common/FlightTimeView.vue'
import BlockPane from '@components/common/BlockPane.vue'
import SignModal from '@components/common/SignModal.vue'
import { Checkbox } from 'ant-design-vue'

export default {
	components: {
		PreServiceTGD1XChecklistPrint,
		PreServiceTGD1XDiagramPrint,
		PhoneLink,
		FlightTimeView,
		BlockPane,
		SignModal,
		'a-checkbox': Checkbox,
	},

	props: {
		service: {
			type: null,
			default: () => [],
		},
		preDelivery: {
			type: null,
			default: () => {},
		},
		canUpdate: {
			type: Boolean,
			default: false,
		},
		isNotInspect: {
			type: Boolean,
			default: false,
		},
		isDeviceOnly : {
			type: Boolean,
			default: false,
		},
		modalLoading: {
			type: Boolean,
			default: false,
		}
	},

	data() {
		return {
			isAccepted: false,
			loading: false,
			visible: false,
			flightTime: '',
			droneReceiveDateTime: '',
			inspectedDateTime: '',
			serviceId: '',
			serviceNumber: '',
			droneSerial: '',
			dronePixhawk: '',
			droneModelName: '',
			customerName: '',
			customerPhone: '',
			customerProvince: '',
			printDate: '',
			openByUsername: '',
			firmwareVersion: '',
			inspectedData: {},
			isPrtClicked: false,
			devices: [],
			others: '',
			batteries: {},
			remark: '',
			checklistType: '',
			signData: {
				customer: {
					img: null,
					name: null
				},
				inspector: {
					img: null,
					name: null
				},
				receiver: {
					img: null,
					name: null
				},
				approver: {
					img: null,
					name: null
				}
			},
			isDocumentSaved: false,
		}
	},
	computed: {
		...mapState('user', ['companies']),
		...mapState('auth', ['currentCompany']),
		displayCompany() {
			return this.companies.find((c) => c.id == this.currentCompany.id)
		},
		containerCssClasses() {
			return [
				'pre_service-checklist',
				{
					'checklist-readonly': !this.canUpdate,
				},
			]
		},
	},
	watch: {
		modalLoading : {
			handler() {
				this.loading = this.modalLoading
			}
		}
	},
	methods: {
		setData(data) {
			this.$nextTick(() => {
				if (data) {
					this.$refs.checklistRef.setData(data)
				}
			})
		},
		openModal(data) {
			this.visible = true
			this.serviceId = data.serviceId
			this.flightTime = data.flightTime
			this.inspectedDateTime = data.inspectedDateTime
			this.serviceNumber = data.serviceNumber
			this.droneSerial = data.droneSerial
			this.dronePixhawk = data.dronePixhawk
			this.droneModelName = data.droneModelName
			this.customerName = data.customerName
			this.customerPhone = data.customerPhone
			this.customerProvince = data.customerProvince
			this.openByUsername = data.openByUsername
			const currentDateTime = new Date()
			this.printDate = currentDateTime.toLocaleDateString() + ':' + currentDateTime.toLocaleTimeString()
			const createdDateTime = this.$dayjs(data.createdDateTime)
			this.droneReceiveDateTime = createdDateTime.format('YYYY-MM-DD') + ' เวลา ' + createdDateTime.format('HH:mm')
			this.devices = data.devices.length === 0 ? [`$t(pre_service.header.devices_unspecified)`] : data.devices
			this.others =  data.others
			this.batteries = data.batteries
			this.firmwareVersion = data.firmwareVersion
			this.inspectedData = data.inspectedData
			this.checklistType = data.checklistType
			this.remark = data.remark
		},
		handleClose() {
			if (this.isDocumentSaved) {
				this.visible = false
				return
			}
			this.$confirm({
				title: this.$t('service.onsite.form.action.update.confirm.title'),
				content: h => <div>{this.$t('service.onsite.form.action.update.confirm.message')}
												<span style="color:red;"><u>{this.$t('service.onsite.form.action.close.confirm.warning')}</u></span>
												{this.$t('service.onsite.form.action.close.confirm.yes_or_no')}
											</div>,
				okText: this.$t('common.ok'),
				cancelText : this.$t('common.cancel'),
				maskClosable: true,
				onOk: () => {
					this.visible = false
				},
			})
		},
		openSignModal(ref) {
			ref.openSignModal()
		},
		handleSign(val, currentSigner) {
			switch (currentSigner) {
				case 'customerRef':
					this.signData.customer.img = val.img
					this.signData.customer.name = val.name
					break
				case 'inspectorRef':
					this.signData.inspector.img = val.img
					this.signData.inspector.name = val.name
					break
				case 'receiverRef':
					this.signData.receiver.img = val.img
					this.signData.receiver.name = val.name
					break
				case 'approverRef':
					this.signData.approver.img = val.img
					this.signData.approver.name = val.name
			}
		},
		handleAccept(e) {
			this.isAccepted = e.target.checked
		},
		handleConvertCommentItemToGenPdf (commentMainElement) {
			let result = '<table style="width: 100%;">'
			const commentElements =  commentMainElement.getElementsByClassName('image-comment-item readonly')
			for (const commentElement of commentElements) {
				const itemNo = commentElement.getElementsByClassName('item-no')[0].innerHTML
				const itemInputReadOnly = commentElement.getElementsByClassName('item-input-read-only')[0].innerHTML
				result = result.concat(`<tr><td class='item-no'>${itemNo}</td><td class='item-input-read-only'>${itemInputReadOnly}</td></tr>`)
			}
			result = result.concat('</table>')
			return result
		},
		handleSave() {
			const signData = this.signData
			if (signData.customer.img  && signData.receiver.img) {
				this.loading = true
				const droneReceiveDateTime = this.droneReceiveDateTime
				const accepted = this.isAccepted
				const companyData = this.displayCompany
				let inspectionData = {}
				if (!this.isDeviceOnly) {
					const commentElement = document.querySelectorAll('.pre-service-modal .image-comment-items')
					const commentFrontElement = commentElement[0]
					const commentBackElement = commentElement[1]
					inspectionData = {
						droneFront: document.querySelectorAll('.pre-service-modal .lower-canvas')[0].toDataURL('image/png'),
						droneBack: document.querySelectorAll('.pre-service-modal .lower-canvas')[1].toDataURL('image/png'),
						commentFront: commentFrontElement.innerHTML !== '' ? this.handleConvertCommentItemToGenPdf(commentFrontElement): commentFrontElement.innerHTML,
						commentBack: commentBackElement.innerHTML !== '' ? this.handleConvertCommentItemToGenPdf(commentBackElement): commentBackElement.innerHTML
					}
				}
				this.$emit('save', {inspectionData, signData, companyData, droneReceiveDateTime, accepted} )
				this.isDocumentSaved = true
			} else {
				this.$message.error(this.$t('pre_service.sign_warning'))
			}
		}
	},
}
</script>

<style lang="less" scoped>
@part-break-screen: @screen-lg;
.sign-area {
	display: block;
	align-items: center;
	width: 123.16px;
	height: 40px;
}
.pre-service-modal {
	height: 600px;
	overflow: auto;
	overscroll-behavior: none;
	div {
		font-size: 0.7rem;
	}
}
.page-header {
	display: flex;
	flex-basis: 100%;
	flex-direction: row;
}
.service-detail-container {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	background-color: @white;
	margin-bottom: 8px;
	border-radius: @border-radius-base;

	.service-col {
		flex-basis: 50%;
		display: flex;
		flex-wrap: nowrap;
		min-width: 0;
		border: 1px solid @gray-4;
		> label {
			font-family: @font-family-title;
			color: @primary-color;
			min-width: 100px;
			flex-basis: 100px;
			background-color: @gray-3;
			padding: 4px 8px;
		}
		> .service-val {
			padding: 4px 8px;
			min-width: 0;
			.service-val-row {
				margin-bottom: 2px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	.service-special-col {
		flex-basis: 100%;
		display: flex;
		flex-wrap: nowrap;
		min-width: 0;
		border: 1px solid @gray-4;
		> label {
			font-family: @font-family-title;
			color: @primary-color;
			min-width: 100px;
			flex-basis: 100px;
			background-color: @gray-3;
			padding: 4px 8px;
		}
		> .service-val {
			padding: 4px 8px;
			min-width: 0;
			.service-val-row {
				margin-bottom: 2px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}
.signature {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	position: relative;
	padding: 10px;
	margin-top: 8px;
	margin-bottom: 0px;

	.signature-item {
		display: flex;
		align-items: center;
		flex-direction: column;
		position: relative;
	}
	.sign-button {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
.signature-img {
	display: block;
	width: 100%;
}
.page-remark {
	display: flex;
	margin-top: 8px;
	justify-content: left;
	align-items: left;
}
</style>
