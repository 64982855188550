<i18n locale="th" lang="yaml" >
error.invalid.checklist_type.title: "ใบซ่อมไม่รองรับ Drone รุ่นนี้"
error.invalid.checklist_type.message : "ใบซ่อมสำหรับ Drone รุ่นนี้ ยังไม่รองรับ ({type}) , ติดต่อทางผู้ดูแลระบบเพื่อปรับปรุง"
</i18n>

<template>
	<div class="invalid-box">
		<div class="title text-error text-bold">
			{{$t('error.invalid.checklist_type.title')}}
		</div>
		<div class="description">
			{{$t('error.invalid.checklist_type.message',{ type: checklistType })}}
		</div>
	</div>
</template>

<script>
export default {
	props : {
		service : {
			type : null,
			default : () => [],
		} ,
		checklistType : {
			type : String,
			default : 'undefined' ,
		}
	} ,

}
</script>
<style lang="less" scoped>
.invalid-box {
	font-family: @font-family-title;
	text-align : center;
	padding : 24px 36px;
	max-width : 600px;
	background-color : @white;
	border : 1px solid @border-color-base;
	margin : 24px auto;
	.title {
		font-size : 3em;

	}
	.description {
		margin-top : 24px;
		font-size: 1em;
	}
}
</style>
