<i18n locale="th" lang="yaml" src="@i18n/service/pre-service.th.yaml"></i18n>
<i18n locale="th" lang="yaml" src="@i18n/service/onfield-service.th.yaml"></i18n>
<i18n locale="th" lang="yaml" >
page.title : "ตรวจรับเครื่อง"
page.description : "ขั้นตอนที่ 3/8"
link_ticket_modal.description : "แสดงผลเฉพาะ Ticket ที่รายงานก่อนรับเครื่อง 2 เดือน"
link_ticket_modal.description.date : "(ตั้งแต่วันที่ {date})"
link_ticket_modal.sub_header : "Ticket ที่เกี่ยวข้อง"

action.cancel_bind.label : "ยกเลิก"
action.bind.label : "ผูกใบงาน"
action.create.pre_service: "สร้างใบตรวจรับ"

pre_service.inspect : "ข้อมูลการตรวจรับ"


pre_service.devices : "อุปกรณ์ที่มากับโดรน"
pre_service.devices.others.placeholder : "เช่น สายไฟ"
pre_service.devices.batteries.placeholder : "S/N หรือ กรอกไม่ระบุ"

pre_service.drone : "ข้อมูลโดรนตอนรับเครื่อง"
pre_service.drone.none : "เป็นงานซ่อมเฉพาะอุปกรณ์เท่านั้น (ไม่ต้องทิ้งโดรนไว้)"

pre_service.field.inspected_by.placeholder : "ตรวจรับโดย"

pre_service.field.inspection : "สภาพของโดรนสำหรับ รุ่น {model}"
pre_service.field.inspection.help : "วงกลม ตำแหน่งที่เสียหาย / ชิ้นส่วนหาย"

pre_service.field.checklist : "การทดสอบการทำงานของโดรนก่อนรับบริการ"
pre_service.field.remark.placeholder : "เช่น สภาพของโดรนหรือ อุปกรณ์อื่น ๆ เพิ่มเติม หรือ สาเหตุที่ไม่ได้ตรวจรับก่อน เป็นต้น"
pre_service.field.inspect : "การตรวจรับเครื่อง"
pre_service.field.is_not_inspect.message : "ไม่ได้ตรวจรับก่อนซ่อม"

pre_service.action.ask_leave.title : "ยืนยันออกจากหน้าจอนี้"
pre_service.action.ask_leave.message : "คุณได้แก้ไขข้อมูลการตรวจรับ และ ยังไม่ได้บันทึกเข้าระบบ, ยืนยันต้องการยกเลิกการแก้ไขนี้ ?"

pre_service.action.ask_reset : "ยกเลิกการแก้ไข"
pre_service.action.ask_reset.title : "@:pre_service.action.ask_reset"
pre_service.action.ask_reset.message : "คุณต้องการยกเลิกการแก้ไข และ นำข้อมูลเดิมในระบบกลับมา ?"

pre_service.action.update.no_change : "ไม่ได้มีการแก้ไขค่า"
pre_service.action.update.no_form : "กรุณาสร้างใบตรวจรับ และบันทึกให้เรียบร้อยก่อนดำเนินการต่อ"
pre_service.action.update.confirm.title : "กรุณายืนยันเพื่อดำเนินการต่อไป"
pre_service.action.update.confirm.message : "คุณต้องการดำเนินการไปยังขั้นตอนถัดไปหรือไม่ ?"
pre_service.action.update.confirm.success : "ดำเนินการเรียบร้อย"

pre_service.files : "ข้อมูลอื่น ๆ ประกอบการตรวจรับ"
pre_service.images.label : "รูป/วีดีโอ ประกอบการตรวจรับ"
pre_service.others.label : "ไฟล์ประกอบอื่น ๆ เช่น Log Files"
pre_service.document_images.label : "รูปใบตรวจรับเครื่องพร้อมลายเซ็นต์"
pre_service.document_files.label : "ไฟล์ใบตรวจรับเครื่องพร้อมลายเซ็นต์"

pre_service.drone.received.button: "ใบตรวจรับโดรน"

pre_service.check.list_yes: "ใช่"
pre_service.check.list_no: "ไม่ใช่"
pre_service.check.list_work: "ได้"
pre_service.check.list_not_work: "ไม่ได้"
pre_service.check.list_no_value: "ไม่ได้ทดสอบ"
pre_service.check.list_unspecified: "ไม่ระบุ"

pre_service_form_data.success: "บันทึกใบตรวจรับโดรนสำเร็จ"
</i18n>

<template>
	<div v-show="!loading" class="page-padding">
		<my-page-header class="main-header" :title="$t('page.title')" :description="$t('page.description')" />
		<template>
			<div class="page-padding pre_service-not-inspect background">
				{{ $t('pre_service.field.inspect') }}:
				<a-checkbox v-model="preServiceValue.is_not_inspect">
					{{ $t('pre_service.field.is_not_inspect') }}
				</a-checkbox>
			</div>
		</template>
		<div class="page-padding background">
			<ErrorMessagePane :error="saveError" />
			<div class="mytab-section-title">
				{{ $t('pre_service.drone') }}
			</div>
			<div v-if="service.is_device_only" class="pre_service-subsection">
				{{ $t('pre_service.drone.none') }}
			</div>
			<div v-else class="pre_service-subsection">
				<div class="pre_service-row">
					<label>{{ $t('pre_service.field.firmware') }} * : </label>
					<div class="pre_service-input-block">
						<FirmwareSelect v-model="preServiceValue.firmware_id"  :firmware-set-id="droneData.firmware.firmware_set_id" @changeFirmware="handleFirmwareChange" />
					</div>
				</div>
				<div class="pre_service-row">
					<label>{{ $t('pre_service.field.flight_time') }} * : </label>
					<div class="pre_service-input-block">
						<FlightTimeInput v-model="preServiceValue.flight_time_sec" />
					</div>
				</div>
			</div>

			<BlockPane :block="preServiceValue.is_not_inspect">
				<span slot="message">
					{{ $t('pre_service.field.is_not_inspect.message') }}
				</span>
				<div class="mytab-section-title">
					{{ $t('pre_service.inspect') }}
				</div>
				<div class="pre_service-subsection">
					<div class="pre_service-row">
						<label>{{ $t('pre_service.field.inspected_by') }} * : </label>
						<div class="pre_service-input-block">
							<UserCompanySelect
								:value="service.open_by_id"
								:company-id="service.company_id"
								disabled
								input-read-only
								required
								:placeholder="$t('pre_service.field.inspected_by.placeholder')"/>
						</div>
					</div>
					<template>
						<div class="pre_service-row">
							<label>{{ $t('pre_service.field.inspected_date') }} * : </label>
							<div class="pre_service-input-block">
								<MyDatePicker :value="openDate" :read-only="true" input-read-only format="DD MMMM YYYY" />
							</div>
						</div>
						<div class="pre_service-row">
							<label>{{ $t('pre_service.field.inspected_time') }} * : </label>
							<div class="pre_service-input-block">
								{{ handleTimeDisplay(openTime) }}
							</div>
						</div>
					</template>
				</div>

				<div class="mytab-section-title">
					{{ $t('pre_service.field.devices') }}
				</div>
				<div class="pre_service-subsection">
					<div class="pre_service-row">
						<a-checkbox-group v-model="devicesData.devices" :options="deviceOptions" @change="handleDeviceDefaultList" />
					</div>
					<div class="pre_service-row">
						<label>{{ $tenum('service.pre_service.devices', 'others') }} : </label>
						<div class="pre_service-input-block">
							<a-input
								v-model="devicesData.others"
								style="max-width: 350px"
								:placeholder="$t('pre_service.devices.others.placeholder')"/>
						</div>
					</div>
					<div class="pre_service-row">
						<label>{{ $tenum('service.pre_service.devices', 'batteries') }} : </label>
						<div class="pre_service-input-block">
							<label>1. </label>
							<a-input
								v-model="devicesData.batteries.no1"
								style="width: 250px"
								:placeholder="$t('pre_service.devices.batteries.placeholder')"/>
						</div>
						<div class="pre_service-input-block">
							<label>2. </label>
							<a-input
								v-model="devicesData.batteries.no2"
								style="width: 250px"
								:placeholder="$t('pre_service.devices.batteries.placeholder')"/>
						</div>
					</div>
				</div>

				<template>
					<div v-if="!isDeviceOnly" class="mytab-section-title">
						{{ $t('pre_service.field.inspection', { model: droneData.model.name }) }}
						<div class="mytab-section-description">
							{{ $t('pre_service.field.inspection.help') }}
						</div>
					</div>
					<div class="pre_service-subsection-full">
						<component
							:is="inspectComponent"
							ref="inspection"
							:can-update="true"
							:service="service"
							:checklist-type="preService.checklist_type"/>
					</div>
					<div v-if="!isDeviceOnly" class="mytab-section-title">
						{{ $t('pre_service.field.checklist') }}
					</div>
					<div class="pre_service-subsection-full">
						<component
							:is="checklistComponent"
							ref="checklist"
							:can-update="true"
							:service="service"
							:checklist-type="preService.checklist_type"/>
						<ServiceOnfieldPreServiceForm
							ref="preServiceFormRef"
							:modal-loading="modalLoading"
							:is-device-only="isDeviceOnly"
							:is-not-inspect="preServiceValue.is_not_inspect"
							:service="service"
							@save="postReceiveDroneDoc"/>
					</div>
				</template>
			</BlockPane>
			<div class="create-pre_service-form">
				<a-button
				icon="form"
				type="info"
				@click="openModal">
					{{$t('action.create.pre_service')}}
				</a-button>
			</div>
			<template>
				<div class="mytab-section-title">
					{{ $t('pre_service.files') }}
				</div>
				<div class="pre_service-subsection files-upload">
					<div class="files-component">
						<ServiceFilesPanel
							ref="serviceFilesRef"
							:error="componentError"
							:service="service"
							:label="$t('pre_service.images.label')"
							:others-label="$t('pre_service.others.label')"
							:can-update="true"/>
					</div>
					<div class="files-component">
						<ServiceFilesPanel
							ref="serviceDocumentFilesRef"
							:error="componentError"
							:service="service"
							:label="$t('pre_service.document_images.label')"
							:others-label="$t('pre_service.document_files.label')"
							:can-update="true"/>
					</div>
				</div>
			</template>

			<div class="mytab-section-title">
				{{ $t('pre_service.field.remark') }}
			</div>
			<div class="pre_service-subsection-full">
				<a-textarea
					v-model="preServiceValue.remark"
					style="max-width: 800px"
					:auto-size="{ minRows: 4, maxRows: 10 }"
					:placeholder="$t('pre_service.field.remark.placeholder')"/>
			</div>

			<div class="page-action-right next-button">
				<a-button
					icon="right-circle"
					type="primary"
					@click="handleSave">
					{{$t('action.next.page')}}
				</a-button>
			</div>
		</div>
	</div>
</template>

<script>
import PageMixin from '@mixins/PageMixin.vue'
import ServiceObjectMixin from '@/src/mixins/service/ServiceObjectMixin.vue'
import { transformToJsonProp} from '@utils/objectUtil'
import { Checkbox, Input } from 'ant-design-vue'
import FirmwareSelect from '@components/firmware/FirmwareSelect.vue'
import MyDatePicker from '@components/input/MyDatePicker.vue'
import UserCompanySelect from '@components/user/UserCompanySelect.vue'
import BlockPane from '@components/common/BlockPane.vue'
import ImageName from '@components/common/ImageName.vue'
import FlightTimeInput from '@components/input/FlightTimeInput.vue'
import FlightTimeView from '@components/common/FlightTimeView.vue'
import InvalidChecklistType from '@components/service/InvalidChecklistType.vue'
import PreServiceTGD1XInspection from '@components/service/tgd1x/PreServiceTGD1XInspection.vue'
import PreServiceTGD1XChecklist from '@components/service/tgd1x/PreServiceTGD1XChecklist.vue'
import ErrorMessagePane from '@components/common/ErrorMessagePane.vue'
import { scrollTopContent, getEnumSelectOptions } from '@utils/formUtil'
import axios from 'axios'
import _isEqual from 'lodash/isEqual'
import ModelError from '@utils/errors/ModelError'
import ServiceFilesPanel from '@components/service/ServiceFilesPanel.vue'
import ServiceOnfieldPreServiceForm from '@components/service/ServiceOnfieldPreServiceForm.vue'
import ApiError from "@utils/errors/ApiError"
import numeral from "numeral"
import { handleTimeFormat } from '@utils/timeUtil'

export default {
	components: {
		'a-checkbox-group': Checkbox.Group,
		'a-checkbox': Checkbox,
		'a-textarea': Input.TextArea,
		FirmwareSelect,
		FlightTimeView,
		FlightTimeInput,
		InvalidChecklistType,
		PreServiceTGD1XInspection,
		PreServiceTGD1XChecklist,
		UserCompanySelect,
		MyDatePicker,
		BlockPane,
		ImageName,
		ErrorMessagePane,
		ServiceFilesPanel,
		ServiceOnfieldPreServiceForm,
	},
	mixins: [PageMixin, ServiceObjectMixin],
	data() {
		return {
			loading: false,
			modalLoading: false,
			service: {},
			preService: {},
			serviceFiles: [],
			serviceDocumentFiles: [],

			preServiceValue: {
				firmware_name: undefined,
				is_not_inspect: false,
				firmware_id: undefined,
				flight_time_sec: undefined,
				inspected_by_id: undefined,
				remark: undefined,
			},
			devicesData: {
				devices: [],
				batteries: {
					no1: '',
					no2: '',
				},
				others: '',
			},
			inspectedDate: undefined,
			inspectedTime: undefined,

			ignorePreventExit: false,
			oldFormData: {},
			saveError: undefined,
			isDeviceOnly: false,

			openDate: null,
			openTime: null,
			checklist: {},
			preServiceFormFileKey: '',
		}
	},
	computed: {
		inspectComponent() {
			if (this.service.id > 0) {
				switch (!this.service.is_device_only) {
					case true:
						return PreServiceTGD1XInspection
					default:
						return undefined
				}
			}
			return undefined
		},
		checklistComponent() {
			if (this.service.id > 0) {
				switch (!this.service.is_device_only) {
					case true:
						return PreServiceTGD1XChecklist
					default:
						return undefined
				}
			}
			return undefined
		},
		deviceOptions() {
			const options = ['remote', 'tablet', 'charger', 'charging_panel']
			return getEnumSelectOptions(this, 'service.pre_service.devices', options)
		},
		componentError() {
			return this.saveError instanceof ModelError ? this.saveError : undefined
		},
	},
	watch: {
		$route(newVal) {
			if (!this.preService || newVal.params.id != this.preService.id) {
				this.fetchPreService()
			}
		},
	},
	mounted() {
		this.fetchPreService()
		this.fetchData()
		this.openDate = this.$dayjs().format('YYYY-MM-DD')
		this.openTime = this.$dayjs()
	},
	beforeMount() {
		window.addEventListener('beforeunload', this.preventExit)
	},
	beforeDestroy() {
		window.removeEventListener('beforeunload', this.preventExit)
	},
	methods: {
		postReceiveDroneDoc(data) {
			const droneDevices = this.devicesData.devices.map((item) => this.$tenum('drone.devices', item))
			const dateTime = new Date().toLocaleDateString() + ': ' + new Date().toLocaleTimeString()

			if (this.$refs.checklist) {
				const checklistData = this.$refs.checklist.getData()
					this.checklist.power = this.transformPower(checklistData.power)
					this.checklist.external = this.transformExternal(checklistData.external)
			}
			const formData = {
				module: 'service',
				renderData: {
					printDate: dateTime,
					inspectedDateTime: this.$dayjs(),
					serviceNumber: this.service.service_no,
					firmwareVersion : this.preServiceValue.firmware_name,
					flightTime: this.hoursDisplay(this.preServiceValue.flight_time_sec),
					droneSerial: this.service.droneData.drone.serial_no,
					dronePixhawk: this.service.droneData.drone.pixhawk_serial_no,
					droneModelName: this.service.droneData.model.name,
					customerName: this.service.customer_name,
					customerPhone: this.service.customer_phone,
					customerProvince: this.service.customer_province,
					openByUsername: this.service.openByData.user.username,
					devices: droneDevices,
					others: this.devicesData.others,
					batteries: this.devicesData.batteries,
					isDeviceOnly: this.isDeviceOnly,
					inspectionData : data.inspectionData,
					signData : data.signData,
					companyData: data.companyData,
					droneReceiveDateTime : data.droneReceiveDateTime,
					accepted: data.accepted,
					checklist: this.checklist,
					isNotInspect: this.preServiceValue.is_not_inspect,
					remark: this.preServiceValue.remark
				}
			}
			axios.post(`/api/services/${this.service.id}/onfield-pre-service-form-upload`,formData).then((response) => {
				this.modalLoading = true
				const uid = Math.floor(Math.random() * response.data.file.file_size)
				const customFile = {
					uid: uid,
					name: response.data.file.filename,
					file_key: response.data.file.file_key,
					size:  response.data.file.file_size,
					type: response.data.file.type,
					status: "done",
				}
				this.$refs.serviceDocumentFilesRef.$refs.fileUploader.addCustomFile(customFile)
				this.$message.success(this.$t('pre_service_form_data.success'))
				this.$refs.preServiceFormRef.handleClose()
				this.preServiceFormFileKey = response.data.file.file_key
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error));
			}).finally(() => {
				this.modalLoading = false
			});
		},
		transformPower(obj) {
			Object.keys(obj).forEach(key => {
				let value
				const v = obj[key]
				if (v === undefined) {
					value = this.$t('pre_service.check.list_no_value')
				} else if (typeof v === 'string' && v === '') {
					value = this.$t('pre_service.check.list_unspecified')
				} else if (typeof v === 'string' && v !== '') {
					value = v
				} else {
					value = v === true ? this.$t('pre_service.check.list_work') : this.$t('pre_service.check.list_not_work')
				}
				obj[key] = value
			})
			return obj
		},
		transformExternal(obj) {
			Object.keys(obj).forEach(key => {
				let value
				const v = obj[key]
				if (v === undefined) {
					value = this.$t('pre_service.check.list_no_value')
				} else if (typeof v === 'string' && v === '') {
					value = this.$t('pre_service.check.list_unspecified')
				} else if (typeof v === 'string' && v !== '') {
					value = v
				} else {
					value = v === true ? this.$t('pre_service.check.list_yes') : this.$t('pre_service.check.list_no')
				}
				obj[key] = value
			})
			return obj
		},
		hoursDisplay(timeSec) {
			if (timeSec > 0) {
				const hours = Math.floor(timeSec/(60*60))
				const left = timeSec - (hours * 60 *60)
				const minutes = Math.floor(left/60)
				const seconds = left - (minutes * 60)

				let rtn = ''
				if (minutes <= 0 && hours <= 0) {
					// display seconds
					rtn = seconds + " "+this.$t('common.second')
				} else if (hours <= 0 && minutes > 0) {
					rtn = numeral(minutes).format("00")+ ":"+ numeral(seconds).format("00") + " "+ this.$t('common.minute')
				} else {
					// display hour and minute
					rtn = numeral(hours).format("0,0")+ ":"+ numeral(minutes).format("00") + " "+ this.$t('common.hour.short')
				}
				return rtn;
			} else {
				return this.$t('common.unspecify')
			}
		} ,
		openModal() {
			const checklistC = this.$refs.checklist
			this.$refs.preServiceFormRef.openModal({
				serviceId: this.service.id,
				inspectedDateTime: this.$dayjs(),
				serviceNumber: this.service.service_no,
				firmwareVersion : this.preServiceValue.firmware_name,
				flightTime: this.preServiceValue.flight_time_sec,
				droneSerial: this.service.droneData.drone.serial_no,
				dronePixhawk: this.service.droneData.drone.pixhawk_serial_no,
				droneModelName: this.service.droneData.model.name,
				customerName: this.service.customer_name,
				customerPhone: this.service.customer_phone,
				customerProvince: this.service.customer_province,
				openByUsername: this.service.openByData.user.username,
				devices: this.devicesData.devices,
				others: this.devicesData.others,
				batteries: this.devicesData.batteries,
				inspectedData: this.$refs.inspection ? this.$refs.inspection.getData() : null,
				checklistType: this.service.checklist_type,
				remark: this.preServiceValue.remark,
				createdDateTime: this.service.created_datetime,
			})
			this.$refs.preServiceFormRef.setData(checklistC ? checklistC.getData(): null)
		},
		fetchData() {
			const serviceId = this.$route.params.id
			if (!serviceId) {
				return
			}
			this.showPageLoading(true)
			axios.get("/api/services/"+serviceId).then((response)=>{
				this.service = response.data.data.service
				this.otherDetails = this.service.otherDetailsData
				this.isDeviceOnly =  this.service.is_device_only
				this.serviceId = this.service.id
				this.preServiceValue.firmware_id = this.service.droneData.firmware.id
				this.preServiceValue.firmware_name = this.service.droneData.firmware.name
				delete this.service.otherDetailsData
				this.addBreadcrumbParams({
					serviceNo : this.service.service_no ,
					droneSerialNo : this.service.droneData.drone.serialNo
				})
			}).catch((error)=>{
				this.fetchError(error)
			}).finally(()=>{
				this.hidePageLoading()
			})
		} ,
		fetchPreService() {
			const serviceId = this.$route.params.id
			if (!serviceId) {
				return
			}
			this.loading = true
			axios
				.get('/api/services/' + serviceId + '/pre-service')
				.then((response) => {
					this.preService = response.data.data.pre_service
					this.serviceFiles = response.data.data.service_files
					this.serviceDocumentFiles = response.data.data.service_document_files
					this.initPreService()
				})
				.catch((error) => {
					this.fetchError(error)
				})
				.finally(() => {
					this.loading = false
				})
		},
		initPreService() {
			this.saveError = undefined
			this.inspectedDate = undefined
			this.inspectedTime = undefined

			if (this.preService.inspected_datetime) {
				const date = this.$dayjs(this.preService.inspected_datetime)
				this.inspectedDate = date.format('YYYY-MM-DD')
				this.inspectedTime = date.format('HH:mm')
			}


			this.$nextTick(() => {
				const inspectComp = this.$refs.inspection
				if (inspectComp && 'setData' in inspectComp) {
					if (this.preService.inspectionData) {
						inspectComp.setData(this.preService.inspectionData)
					} else {
						inspectComp.setData({})
					}
				}
				const checklistComp = this.$refs.checklist
				if (checklistComp) {
					if (this.preService.checklistData) {
						checklistComp.setData(this.preService.checklistData)
					} else {
						checklistComp.setData({})
					}
				}
				if (this.$refs.serviceFilesRef) {
					this.$refs.serviceFilesRef.setData(this.serviceFiles)
				}
				if (this.$refs.serviceDocumentFilesRef) {
					this.$refs.serviceDocumentFilesRef.setData(this.serviceDocumentFiles)
				}
				this.$nextTick(() => {
					this.oldFormData = this.dumpFormData()
				})
			})
		},
		handleFirmwareChange(val) {
			this.preServiceValue.firmware_name = val.name
		},
		handleDeviceDefaultList(value) {
			this.devicesData.devices = [...value]
		},
		dumpFormData() {
			const formData = {
				id: this.preService.id,
				...this.preServiceValue,
				devicesData: { ...this.devicesData },
				service_files: [],
			}
			formData.inspected_by_id = this.service.open_by_id
			formData.inspected_datetime = this.openDate + ' ' + this.openTime.format('HH:mm') + ':00'
			const checklistC = this.$refs.checklist
			if (checklistC && typeof checklistC.getData === 'function') {
				formData.checklistData = checklistC.getData()
			}
			const inspectC = this.$refs.inspection
			if (inspectC && typeof inspectC.getData === 'function') {
				formData.inspectionData = inspectC.getData()
			}
			if (this.$refs.serviceFilesRef) {
				formData.service_files.push({ upload_type: 'pre-service', files: this.$refs.serviceFilesRef.getData() })
			}
			if (this.$refs.serviceDocumentFilesRef) {
				formData.service_files.push({upload_type: 'pre-service-doc', files: this.$refs.serviceDocumentFilesRef.getData()})
			}
			return formData
		},
		handleSave() {
			const preFormData = this.dumpFormData()
			if (!this.isDataChange(preFormData)) {
				this.$message.info(this.$t('pre_service.action.update.no_change'))
				return
			}

			const serviceFiles = preFormData.service_files
			delete preFormData.service_files
			const docForm = serviceFiles[1].files.find(file => file.file_key === this.preServiceFormFileKey)
			if (!docForm) {
				this.$message.error(this.$t('pre_service.action.update.no_form'))
				return
			}

			this.$confirm({
				title: this.$t('pre_service.action.update.confirm.title'),
				content: this.$t('pre_service.action.update.confirm.message'),
				okText: this.$t('common.ok'),
				cancelText : this.$t('common.cancel'),
				maskClosable: true,
				onOk: () => {
					this.showPageLoading(true)
					// transform preFormData
					transformToJsonProp(preFormData, 'devicesData', 'devices_json')
					transformToJsonProp(preFormData, 'checklistData', 'checklist_json')
					transformToJsonProp(preFormData, 'inspectionData', 'inspection_json')

					const formData = {
						pre_service: preFormData,
						service_files: serviceFiles,
					}
					axios.put(`/api/services/${this.service.id}/pre-service`, formData)
						.then((res) => {
							return axios.post(`/api/services/${this.service.id}/update-onfield-state`, { state: 'service_job' })
						})
						.then((res) => {
							return axios.post(`/api/services/${this.service.id}/update-status/in_progress`)
						})
						.then((res) => {
							this.ignorePreventExit = true
							this.$message.success(this.$t('pre_service.action.update.confirm.success'))
							this.$router.push({ name: 'onfield/service_job', params:{id:this.service.id} })
						})
						.catch((error) => {
							this.saveError = error
							scrollTopContent()
						})
						.finally(() => {
							this.hidePageLoading()
						})
				},
			})
		},
		preventExit(event) {
			if (this.checkPreventExit()) {
				event.preventDefault()
				event.returnValue = ''
			}
		},
		isDataChange(formData) {
			const change = !_isEqual(this.oldFormData, formData)
			return change
		},
		checkPreventExit(formData = null) {
			if (this.ignorePreventExit) return false
			if (!formData) {
				formData = this.dumpFormData()
			}
			return this.isDataChange(formData)
		},
		handleTimeDisplay(date){
			return handleTimeFormat(date)
		}
	},

	beforeRouteLeave(routeTo, routeFrom, next) {
		const formData = this.dumpFormData()
		if (this.checkPreventExit(formData)) {
			this.$confirm({
				title: this.$t('pre_service.action.ask_leave.title'),
				content: this.$t('pre_service.action.ask_leave.message'),
				okText: this.$t('common.confirm'),
				maskClosable: true,
				onOk: () => {
					next()
				},
			})
		} else {
			next()
		}
	},
}
</script>

<style lang="less" scoped>
.create-pre_service-form {
	margin: 0 0 10px 0;
}
.pre_service-subsection {
	margin-left: 64px;
	margin-bottom: 32px;
	.mobile & {
		margin-left: 16px;
	}
}
.pre_service-subsection-full {
	margin-bottom: 32px;
}

.pre_service-row {
	margin-bottom: 12px;
	label {
		color: @info-color;
		.mobile & {
			margin-bottom: 4px;
		}
	}
}
.pre_service-input-view {
	margin-left: 8px;
	display: inline-block;
}
.pre_service-input-block {
	display: inline-block;
	margin-right: 8px;
	.mobile & {
		display: block;
		margin-bottom: 4px;
		margin-right: 0;
	}
}
.drone-received-container {
	display: flex;
	margin-bottom: 12px;
	flex-direction: row;
	justify-content: space-between;
}
.pre_service-subsection-full {
	margin-bottom: 32px;
}
.right-button {
	margin: 0 0.2rem 0 auto;
}
.main-header {
	text-align: center;
}
.background {
	background : white;
	.mobile & {
		padding : 12px 16px;
	}
}
.files-upload {
	display: flex;
	flex-direction: row;
	margin-right: 2rem;
}
.files-component {
	margin-right: 2rem;
}
.page-action-right {
	float: right;
	margin-bottom : 8px;
}
</style>
