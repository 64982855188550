<i18n locale="th" lang="yaml" >
pre_service.image_comment.front : "ด้านหน้า"
pre_service.image_comment.back : "ด้านหลัง"
</i18n>

<template>
	<div class="pre_service-images">
		<div class="pre_service-image-comment">
			<div class="topic">{{$t('pre_service.image_comment.front')}}</div>
			<ImageCommentInput
				ref="inspectFront"
				:image-url="require('@assets/images/tgd1x_inspect_front.png')"
				:width-offset="64"
				:readonly="!canUpdate"
				:canvas-width="500"
				:width="600"
				:height="250"
				size="small"/>
		</div>
		<div class="pre_service-image-comment">
			<div class="topic">{{$t('pre_service.image_comment.back')}}</div>
			<ImageCommentInput
				ref="inspectBack"
				:image-url="require('@assets/images/tgd1x_inspect_back.png')"
				:width-offset="64"
				:readonly="!canUpdate"
				:canvas-width="500"
				:width="600"
				:height="250"
				size="small"/>
		</div>
	</div>
</template>

<script>
import ImageCommentInput from "@components/input/ImageCommentInput.vue"
export default {
	components : {
		ImageCommentInput ,
	} ,
	props : {
		service : {
			type : null,
			default : () => []
		} ,
		canUpdate : {
			type : Boolean,
			default : false,
		}
	} ,
	methods : {
		setData(data) {
			if (data && 'front' in data) {
				this.$refs.inspectFront.setData(data.front)
			} else {
				this.$refs.inspectFront.setData([])
			}
			if (data && 'back' in data) {
				this.$refs.inspectBack.setData(data.back)
			} else {
				this.$refs.inspectBack.setData([])
			}
		} ,
		getData() {
			return {
				front : this.$refs.inspectFront.getData() ,
				back : this.$refs.inspectBack.getData() ,
			}
		}
	}
}
</script>

<style lang="less" scoped>
.pre_service-images {
	display : flex;
	flex-wrap: wrap;
	.pre_service-image-comment {
		flex-grow: 1;
		flex-basis : 475px;
		margin-bottom : 24px;
		.topic {
			font-family: @font-family-title;
			text-align: center;
			margin-bottom : 8px;
			color : @info-color;
			font-size : 1.1em;
		}
	}
}


</style>
