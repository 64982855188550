<i18n locale="th" lang="yaml" >
flight_time.value.zero : "-"
flight_time.value : "{count} วินาที"
</i18n>

<template>
	<MyTooltip :title="$tc_my('flight_time.value', timeSeconds)">
		<span>
			{{ hoursDisplay }}
		</span>
	</MyTooltip>
</template>

<script>
import MyTooltip from '@components/common/MyTooltip.vue'
import { hoursDisplayStr } from '@utils/stringUtil'

export default {
	components: {
		MyTooltip,
	},
	props: {
		timeSeconds: {
			type: Number,
			default: undefined,
		},
	},
	computed: {
		hoursDisplay() {
			return hoursDisplayStr(
				this.timeSeconds,
				this.$t('common.hour.short'),
				this.$t('common.minute'),
				this.$t('common.second'),
				this.$t('common.unspecify')
			)
		},
	},
}
</script>
