<i18n locale="th" lang="yaml" >
tgd1x.pre.checklist.camera_model : "กล้องรุ่น"

tgd1x.pre.checklist.pass : "ได้"
tgd1x.pre.checklist.fail : "ไม่ได้"
tgd1x.pre.checklist.label : "เมื่อเสียบแบตเตอรี่แล้ว"

tgd1x.pre.checklist.power.camera : "กล้องรุ่น"
tgd1x.pre.checklist.power.camera.placeholder : "รุ่นของกล้อง"
tgd1x.pre.checklist.power.camera_switching : "สลับกล้อง"
tgd1x.pre.checklist.power.camera_top : "- กล้องหน้า"
tgd1x.pre.checklist.power.camera_bottom : "- กล้องล่าง"
tgd1x.pre.checklist.power.transmitter : "Transmitter"
tgd1x.pre.checklist.power.light_front : "ไฟหน้า"
tgd1x.pre.checklist.power.light_bottom : "ไฟล่าง"
tgd1x.pre.checklist.power.laser : "เลเซอร์"
tgd1x.pre.checklist.power.pump : "ปั๊ม"

tgd1x.pre.checklist.external.usb_a_connector : "ช่องต่อสาย USB A ไม่โยก"
tgd1x.pre.checklist.external.microusb_connector : "ช่องชาร์ตแบต Micro USB ไม่โยก"
tgd1x.pre.checklist.external.remote_antenna : "เสาอากาศรีโมท ไม่เสียหาย"
tgd1x.pre.checklist.external.tablet_holder : "ที่จับจอไม่หลวม น็อตครบ"

tgd1x.pre_service.checklist.pass_all : "ผ่านหมด"
</i18n>

<template>
	<div :class="containerCssClasses">
		<div class="pre_service-checklist-block">
			<div class="checklist-row checklist-row-title checklist-row-border">
				<label class="title">
					{{$t('tgd1x.pre.checklist.label')}}
				</label>
				<div v-trim class="checklist-check">
					<label class="ant-radio-wrapper">{{$t('tgd1x.pre.checklist.pass')}}</label>
					<label class="ant-radio-wrapper">{{$t('tgd1x.pre.checklist.fail')}}</label>
				</div>
			</div>

			<ServiceChecklistRow
				v-model="checklistData.power.camera_status"
				class="checklist-row-border"
				:can-update="canUpdate">
				<div slot="label" class="tgd1x-camera-model">
					<label>
						{{$t('tgd1x.pre.checklist.power.camera')}}
					</label>
					<a-input v-if="canUpdate" v-model="checklistData.power.camera_model" type="text"
						size="small"
						class="tgd1x-camera-model-input"
						:placeholder="$t('tgd1x.pre.checklist.power.camera.placeholder')"/>
					<label v-else class="text-info">
						: {{checklistData.power.camera_model | emptyCheck}}
					</label>
				</div>

			</ServiceChecklistRow>

			<ServiceChecklistRow
				v-model="checklistData.power.camera_switching"
				:can-update="canUpdate"
				:label="$t('tgd1x.pre.checklist.power.camera_switching')"/>
			<ServiceChecklistRow
				v-model="checklistData.power.camera_top"
				:can-update="canUpdate">
				<label slot="label" class="sub">{{$t('tgd1x.pre.checklist.power.camera_top')}}</label>
			</ServiceChecklistRow>
			<ServiceChecklistRow
				v-model="checklistData.power.camera_bottom"
				class="checklist-row-border"
				:can-update="canUpdate">
				<label slot="label" class="sub">{{$t('tgd1x.pre.checklist.power.camera_bottom')}}</label>
			</ServiceChecklistRow>

			<ServiceChecklistRow
				v-model="checklistData.power.transmitter"
				class="checklist-row-border"
				:can-update="canUpdate"
				:label="$t('tgd1x.pre.checklist.power.transmitter')"/>
			<ServiceChecklistRow
				v-model="checklistData.power.light_front"
				class="checklist-row-border"
				:can-update="canUpdate"
				:label="$t('tgd1x.pre.checklist.power.light_front')"/>
			<ServiceChecklistRow
				v-model="checklistData.power.light_bottom"
				class="checklist-row-border"
				:can-update="canUpdate"
				:label="$t('tgd1x.pre.checklist.power.light_bottom')"/>

			<ServiceChecklistRow
				v-model="checklistData.power.laser"
				class="checklist-row-border"
				:can-update="canUpdate"
				:label="$t('tgd1x.pre.checklist.power.laser')"/>

			<ServiceChecklistRow
				v-model="checklistData.power.pump"
				class="checklist-row-border"
				:can-update="canUpdate"
				:label="$t('tgd1x.pre.checklist.power.pump')"/>
			<div v-if="canUpdate" class="checklist-row checklist-row-footer checklist-row-border">
				<div>
					<a-checkbox
						:checked="powerCheckAll"
						@change="handlePowerCheckAll">
						{{$t('tgd1x.pre_service.checklist.pass_all')}}
					</a-checkbox>
				</div>
			</div>
		</div>
		<div class="pre_service-checklist-block">
			<div class="checklist-row checklist-row-title checklist-row-border">
				<div v-trim class="checklist-check">
					<label class="ant-radio-wrapper">{{$t('common.yes')}}</label>
					<label class="ant-radio-wrapper">{{$t('common.no')}}</label>
				</div>
			</div>

			<ServiceChecklistRow
				v-model="checklistData.external.usb_a_connector"
				class="checklist-row-border"
				:can-update="canUpdate"
				:label="$t('tgd1x.pre.checklist.external.usb_a_connector')"/>

			<ServiceChecklistRow
				v-model="checklistData.external.microusb_connector"
				class="checklist-row-border"
				:can-update="canUpdate"
				:label="$t('tgd1x.pre.checklist.external.microusb_connector')"/>

			<ServiceChecklistRow
				v-model="checklistData.external.remote_antenna"
				class="checklist-row-border"
				:can-update="canUpdate"
				:label="$t('tgd1x.pre.checklist.external.remote_antenna')"/>

			<ServiceChecklistRow
				v-model="checklistData.external.tablet_holder"
				class="checklist-row-border"
				:can-update="canUpdate"
				:label="$t('tgd1x.pre.checklist.external.tablet_holder')"/>
			<div v-if="canUpdate" class="checklist-row checklist-row-footer checklist-row-border">
				<div>
					<a-checkbox
						:checked="externalCheckAll"
						@change="handleExternalCheckAll">
						{{$t('tgd1x.pre_service.checklist.pass_all')}}
					</a-checkbox>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {Checkbox} from "ant-design-vue"
import ServiceChecklistRow from "../ServiceChecklistRow.vue"
import _set from "lodash/set"
import _get from "lodash/get"
import {replaceDeepProperty,copyDeep} from "@utils/objectUtil"
function _defaultChecklistData() {
	return {
		power : {
			camera_model : '' ,
			camera_status : undefined ,
			camera_switching : undefined,
			camera_top : undefined,
			camera_bottom : undefined,
			transmitter : undefined,
			light_front : undefined,
			light_bottom : undefined,
			laser : undefined,
			pump : undefined,
		} ,

		external : {
			usb_a_connector : undefined,
			microusb_connector : undefined,
			remote_antenna : undefined,
			tablet_holder : undefined,
		}

	}
}

export default {
	components : {
		ServiceChecklistRow ,
		"a-checkbox" : Checkbox,
	} ,

	props : {
		service : {
			type : null,
			default : () => []
		} ,
		canUpdate : {
			type : Boolean,
			default : false,
		}
	} ,
	data() {
		return {
			checklistData : _defaultChecklistData()
		}
	} ,
	computed : {
		powerCheckAll() {
			let all = true
			for(const prop in this.checklistData.power) {
				if (prop != 'camera_model')
					all = all && _get(this.checklistData,"power."+prop)
			}
			return all
		} ,
		externalCheckAll() {
			let all = true
			for(const prop in this.checklistData.external) {
				all = all && _get(this.checklistData,"external."+prop)
			}
			return all
		} ,
		containerCssClasses() {
			return [
				'pre_service-checklist' ,
				{
					'checklist-readonly' : !this.canUpdate
				}
			]
		} ,
		checkOptions() {
			return [
				{'value' : true} ,
				{'value' : false}
			]
		}
	} ,

	methods : {
		setData(data) {
			this.checklistData = _defaultChecklistData()
			if (data) {
				replaceDeepProperty(this.checklistData,data)
			}
		} ,
		getData() {
			return copyDeep(this.checklistData)
		} ,
		handleClearChecklist(attribute) {
			_set(this.checklistData,attribute,undefined)
		} ,
		handlePowerCheckAll(event) {
			let checked = event.target.checked
			if (!checked)
				checked = undefined
			for(const prop in this.checklistData.power) {
				if (prop != 'camera_model')
					_set(this.checklistData,"power."+prop,checked)
			}
		} ,
		handleExternalCheckAll(event) {
			let checked = event.target.checked
			if (!checked)
				checked = undefined
			for(const prop in this.checklistData.external) {
				_set(this.checklistData,"external."+prop,checked)
			}
		}
	}
}
</script>

<style lang="less" scoped>
.pre_service-checklist {
	display : flex;
	align-items: stretch;
	flex-wrap: wrap;

	.pre_service-checklist-block {
		flex-grow: 1;
		flex-basis: 325px;
		min-width : 325px;
		max-width: 400px;
		margin-bottom : 24px;
		padding-right : 16px;

		.mobile  & {
			padding-right : 0;
			max-width: 100%;
			flex-basis: 275px;
			min-width : 275px;
		}
	}
}
.tgd1x-camera-model {
	float :left;
}
.tgd1x-camera-model-input {
	width : 135px;
	margin-left : 4px;
	font-size : 13px;
}
</style>
