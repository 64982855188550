<i18n locale="th" lang="yaml" >
service_files.section.galleries : "{label}"
service_files.section.galleries.empty : "ไม่พบ {label}"
service_files.section.others : "{othersLabel}"
service_files.section.others.empty : "ไม่พบ {othersLabel}"
</i18n>

<template>
	<div class="service-files-panel">
		<ErrorMessagePane :error="error" :only-model-keys="[errorKey]"/>
		<div class="file-section-title">
			{{$t('service_files.section.galleries',{ label })}}
		</div>
		<ImageVideoUploader
				ref="imageUploader"
				module="service"
				:view-only="!canUpdate"
				:max-image-height="2048" :max-image-width="2048"
				@remove_initial="handleGalleryRemoveInitial"
				@change="handleGalleryChange"/>
		<div v-if="!canUpdate && isImageFilesEmpty" class="file-section-empty">
			{{$t('service_files.section.galleries.empty',{ label })}}
		</div>

		<div class="file-section-title">
			{{$t('service_files.section.others', { othersLabel })}}
		</div>

		<div class="service-file-list">
			<div v-for="file in currentFiles" :key="file.id" class="service-file">
				<MyTooltip trigger="hover" :title="file.filename" display-mode="white">
					<a @click="downloadFile(file)">
						<component :is="file.icon" /> {{file.displayName}}
					</a>
				</MyTooltip>
				<a-button v-if="canUpdate" type="link" icon="delete" class="service-file-delete"
					size="small"
					@click="handleFileRemove(file)"/>
			</div>
		</div>

		<div v-if="!canUpdate && currentFiles.length == 0" class="file-section-empty">
			{{$t('service_files.section.others.empty', { othersLabel })}}
		</div>

		<FilesUploader
				v-if="canUpdate"
				ref="fileUploader"
				module="service"
				class="service-files-uploader"
				:check-support-file="checkServiceFile"
				@upload-state="handleFilesUploadState"
				@change="handleFilesChange" />
	</div>
</template>

<script>
import axios from "axios"
import PageMixin from "@mixins/PageMixin.vue"
import {TIMEOUT_FILE_DOWNLOAD} from "@utils/axiosUtil"
import ImageVideoUploader from "@components/input/ImageVideoUploader.vue"
import FilesUploader from "@components/input/FilesUploader.vue"
import MyTooltip from "@components/common/MyTooltip.vue"
import ApiError from "@utils/errors/ApiError"
import fileDownload from 'js-file-download'
import {isStringEmpty} from "@utils/stringUtil"
import DroneIcon from "@components/icon/DroneIcon.vue"
import OthersIcon from "vue-material-design-icons/FileTableOutline.vue"
import ErrorMessagePane from "@components/common/ErrorMessagePane.vue"
export default {
	components : {
		ImageVideoUploader ,FilesUploader, MyTooltip ,
		ErrorMessagePane ,
	} ,
	mixins : [PageMixin] ,
	props : {
		service : {
			type : null,
			default : () => []
		} ,
		canUpdate : {
			type : Boolean ,
			default : false,
		} ,
		label : {
			type : String,
			default : undefined,
		} ,
		othersLabel : {
			type : String,
			default : undefined,
		} ,
		error : {
			type : null,
			default : undefined,
		} ,
		errorKey : {
			type : String,
			default : 'service_files',
		} ,
		fileTypeField : {
			type : String,
			default : 'service_file_type'
		} ,
	} ,
	data() {
		return {
			serviceFiles : [] ,

			newImageFiles : [] ,
			deleteImageFiles : [],
			currentFiles : [],
			newFiles : [] ,
			deleteFiles : [] ,

			filesUploadState : '',
			imagesUploadState : '',

			isImageFilesEmpty : false,
		}
	} ,
	methods : {
		setData(data) {
			this.newImageFiles = []
			this.deleteImageFiles = []
			this.newFiles = []
			const imageFiles = data.filter((f)=> f[this.fileTypeField] == 'gallery')
			this.isImageFilesEmpty = imageFiles.length == 0
			this.$refs.imageUploader.clear()
			this.$refs.imageUploader.setFileList(imageFiles)
			this.currentFiles = [
				...data.filter((f) => f[this.fileTypeField] != 'gallery').map((f)=>{
					let displayName = f.filename.toLowerCase()
					let icon = OthersIcon
					if (displayName.endsWith('.bin')) {
						icon = DroneIcon
						displayName = this.service.droneData.drone.name +"_" + this.service.service_no+"_"+displayName;
					}
					return {
						...f,
						icon ,
						displayName : displayName,
					}
				})
			]

		} ,
		getData() {
			const serviceFiles = []
			for(const fileKey of this.deleteImageFiles) {
				serviceFiles.push({
					'mode' : 'delete',
					'file_key' : fileKey,
					'file_type' : 'gallery',
				})
			}
			for(const fileKey of this.newImageFiles) {
				serviceFiles.push({
					'mode' : 'add' ,
					'file_key' : fileKey,
					'file_type' : 'gallery',
				})
			}
			for(const file of this.newFiles) {
				serviceFiles.push({
					'mode' : 'add',
					'file_key' : file.file_key ,
					'file_type' : 'others',
				})
			}
			for(const fileKey of this.deleteFiles) {
				serviceFiles.push({
					'mode' : 'delete',
					'file_key' : fileKey ,
					'file_type' : 'others',
				})
			}
			if (this.canUpdate && this.$refs.fileUploader.getCustomFileList()) {
				this.$refs.fileUploader.getCustomFileList().forEach((files) => {
					if (files.status !== 'removed') {
						serviceFiles.push({
							'mode' : 'add',
							'file_key' : files.file_key ,
							'file_type' : files.type,
						})
					}
				})
			}
			return serviceFiles
		} ,
		handleGalleryRemoveInitial(file) {
			this.deleteImageFiles.push(file.file_key)
		} ,
		handleGalleryChange(data) {
			this.newImageFiles = data.files.filter((f)=> !f.id).map((f) => f.file_key)
		} ,
		handleFilesChange(data) {
			if (data.type === 'add') this.newFiles = [...data.files]
		} ,
		handleFileRemove(file) {
			const index = this.currentFiles.findIndex((f) => f.file_key == file.file_key)
			if (index >= 0)
				this.currentFiles.splice(index,1)
			this.deleteFiles.push(file.file_key)
		} ,
		handleFilesUploadState(state){
			this.filesUploadState = state
		} ,
		checkServiceFile(file) {
			const type = file.type
			if (!isStringEmpty(type) && (type.indexOf('video/') === 0 || type.indexOf('image/') ===0))
				return false
			else
				return true
		} ,
		downloadFile(file) {
			this.showPageLoading(true)
			axios.get("/api/file/download",{
				responseType: 'blob' ,
				params : {file : file.file_key} ,
				timeout : TIMEOUT_FILE_DOWNLOAD
			}).then((response) => {
				fileDownload(response.data,file.displayName);
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error));
			}).finally(() => {
				this.hidePageLoading()
			})
		}
	}
}
</script>

<style lang="less" scoped>
.service-files-panel {
	max-width : 900px;
}
.file-section-title {
	font-family : @font-family-title;
	text-decoration: underline;
	color : @secondary-color;
	margin-bottom : 12px;
}
.file-section-empty {
	color : @error-color;
	padding-left : 24px;
	margin-bottom : 16px;

}

.service-file {
	position : relative;
	padding-right : 24px;

	margin-bottom : 4px;

	.service-file-delete {
		position : absolute;
		top : 0;
		right : 0;
		color : @error-color;
	}
}
</style>
