<i18n locale="th" lang="yaml" >
block.default : "ไม่สามารถแก้ไขได้"
</i18n>

<template>
	<div class="block-container">
		<slot />
		<div v-if="block" class="block-mask">
			<div class="block-message">
				<slot name="message">
					{{$t('block.default')}}
				</slot>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props : {
		block : {
			type : Boolean,
			default : false
		} ,
	} ,
}
</script>

<style lang="less" scoped>
.block-container {
	position: relative;


}
.block-mask {
	position : absolute;
	z-index : 990;
	top : 0;
	right: 0;
	bottom : 0;
	left : 0;
	user-select: none;
	pointer-events: none;
	background-color : @gray-4;
	border-radius: @border-radius-base;
	border : 1px solid @gray-6;
	opacity: 0.8;
	.block-message {
		text-align : center;
		font-family: @font-family-title;
		position: absolute;
		font-size : 2em;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);

	}
}
</style>
