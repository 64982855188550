<template>
	<div>
		<span v-if="!disabled && readOnly">{{ convertToDisplayDisabledMode(internalValue) }}</span>
		<a-date-picker
			v-else
			v-model="internalValue"
			:disabled="disabled"
			:default-value="internalDefaultValue"
			:disabled-date="disabledDate"
			:disabled-time="disabledTime"
			input-read-only
			:format="format"/>
	</div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import {DatePicker} from "ant-design-vue"
export default {
	components : {
		"a-date-picker" : DatePicker
	} ,
	inheritAttrs : false,
	model : {
		prop : 'value',
		event : 'change',
	} ,
	props : {
		value : {
			type : String ,
		} ,
		defaultValue : {
			type : String,
		} ,
		disabledDate : {
			type : Function,
			default : undefined
		} ,
		disabledTime : {
			type : Function ,
			default : undefined,
		} ,
		disabled : {
			type : Boolean,
			default : false
		} ,
		format : {
			type : String,
			default : 'D MMMM YYYY'
		} ,
		readOnly : {
			type: Boolean,
			default: false
		}
	} ,
	data() {
		return {
			internalValue : null
		}
	} ,
	computed : {
		internalDefaultValue() {
			if (this.defaultValue)
				return this.convertToDisplay(this.defaultValue)
			else
				return null;
		}
	} ,
	watch : {
		internalValue(newVal,oldVal) {
			if (this.convertToValue(newVal) === this.convertToValue(oldVal))
				return;
			this.$emit('change',this.convertToValue(newVal))
		} ,
		value(newVal,oldVal) {
			if (newVal === oldVal)
				return;
			this.internalValue = this.convertToDisplay(newVal)
		}
	} ,
	mounted() {
		this.internalValue = this.convertToDisplay(this.value)
	} ,
	methods : {
		convertToDisplay(value) {
			if (!value)
				return null;
			return this.$dayjs(value)
		} ,
		convertToValue(value) {
			if (!value)
				return null;
			return this.$dayjs(value,this.format).format('YYYY-MM-DD')
		},
		convertToDisplayDisabledMode(value) {
			if (!value) return '';

			return this.$dayjs(value).format('DD MMMM YYYY')
		}
	}
}
</script>
